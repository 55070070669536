import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import axios from 'axios';

function exportList(pks, fields, searchParams) {
  const data = pks ? { pks: pks } : {};
  data.fields = fields;
  const url = 'backlog-items/export' + (searchParams ? ('?' + searchParams.toString()) : '');
  return axios.put(url, data);
}

function BulkExportModalContent(props) {
  const { pks, setShow, requestParams } = props;
  const [loading, setLoading] = useState(false);
  const [format, setFormat] = useState('csv');
  const fields = [
    {
      name: 'organization',
      label: t`Organization`
    },
    {
      name: 'product',
      label: t`Product`
    },
    {
      name: 'reference',
      label: t`Reference`
    },
    {
      name: 'parent',
      label: t`Parent item`
    },
    {
      name: 'title',
      label: t`Title`
    },
    {
      name: 'description',
      label: t`Description`
    },
    {
      name: 'item_type',
      label: t`Item type`
    },
    {
      name: 'epic',
      label: t`Epic`
    },
    {
      name: 'tags',
      label: t`Tags`
    },
    {
      name: 'priority',
      label: t`Priority`
    },
    {
      name: 'status',
      label: t`Status`
    },
    {
      name: 'resolution',
      label: t`Resolution`
    },
    {
      name: 'business_value',
      label: t`Business value`
    },
    {
      name: 'affects_versions',
      label: t`Affects versions`
    },
    {
      name: 'fix_versions',
      label: t`Fix versions`
    },
    {
      name: 'sprint',
      label: t`Sprint`
    },
    {
      name: 'assignee',
      label: t`Assignee`
    },
    {
      name: 'reporter',
      label: t`Reporter`
    },
    {
      name: 'estimate',
      label: t`Estimate`
    },
    {
      name: 'estimate_unit',
      label: t`Estimate unit`
    },
    {
      name: 'total_work',
      label: t`Total work`
    },
    {
      name: 'default_account',
      label: t`Default account`
    },
    {
      name: 'deadline',
      label: t`Deadline`
    },
    {
      name: 'created_at',
      label: t`Create date`
    },
    {
      name: 'done_date',
      label: t`Done date`
    },
  ];
  const [fieldValues, setFieldValues] = useState(
    Object.fromEntries(fields.map(f => [f.name, true])));
  const setOpt = e => {
    setFormat(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    requestParams.set('format', format);
    const selectedFields = fields.filter(f => fieldValues[f.name]).map(f => f.name);
    if (selectedFields.length === 0) {
      setLoading(false);
      return;
    }
    exportList(pks, selectedFields, requestParams).then(data => {
      setLoading(false);
      addMessage(
        'export-sucess-' + data.data.job.pk,
        t`Export enqueued`,
        t`New export in queue. Please consult your job list to retrieve the file when finished.`
      );
      setShow(false);
    }).catch(error => {
      setLoading(false);
      addMessage('export-fail', t`Unknown error`, t`Impossible to export selected items`);
    });
  };

  const updateAll = (value) => {
    setFieldValues(Object.fromEntries(fields.map(f => [f.name, value])));
  };

  const fieldCheckBox = (f) => (
    <Form.Check
      key={'field-' + f.name}
      name={'field-' + f.name}
      id={'field-' + f.name}
      label={f.label}
      checked={fieldValues[f.name]}
      onChange={e => {
        setFieldValues(current => ({ ...current, [f.name]: e.target.checked }));
      }} />
  );

  const isFieldSelected = fields.filter(f => fieldValues[f.name]).length > 0;

  return (
    <form className="relative" method="PUT" action="" onSubmit={onSubmit}>
      { loading && <LoaderContainer /> }
      <div className="form-group">
        <div className="label">
          <Trans>File format</Trans>
        </div>
        <Form.Check
          inline
          label={t`CSV`}
          name="export-format"
          type="radio"
          id="export-format-csv"
          value="csv"
          checked={format === 'csv'}
          onChange={setOpt}
        />
        <Form.Check
          inline
          label={t`Excel`}
          name="export-format"
          type="radio"
          id="export-format-xlsx"
          value="xlsx"
          checked={format === 'xlsx'}
          onChange={setOpt}
        />
      </div>
      <div className="mt-1">
        <div className="label">
          <Trans>Values to export</Trans>
        </div>
        <div>
          <button type="button" className="btn btn-link p-0 text-small" onClick={() => updateAll(true)}>
            <Trans>Select all</Trans></button>
          <button type="button" className="btn btn-link p-0 ms-2 text-small" onClick={() => updateAll(false)}>
            <Trans>Unselect all</Trans></button>
        </div>
        <div className="row align-items-start">
          <div className="col-sm-6">
            {fields.slice(0, 13).map(fieldCheckBox)}
          </div>
          <div className="col-sm-6">
            {fields.slice(13, fields.length).map(fieldCheckBox)}
          </div>
        </div>
        {isFieldSelected || (
          <div className="error text-danger text-small">
            <Trans>Please select at least one value.</Trans>
          </div>
        )}
      </div>
      <div className="form-group pt-2">
        <input type="submit" className="btn btn-primary me-2" value={t`Export`} />
        <input type="button" className="btn btn-outline-secondary" value={t`Cancel`} onClick={() => { setShow(false); }} />
      </div>

    </form>
  );
}

function BulkExportModal(props) {
  const { pks, dropdownItem, count, requestParams, className, limit } = props;
  const [show, setShow] = useState(false);
  if ((pks && pks.length < 1) || (!pks && !count)) {
    return null;
  }
  const size = count || pks.length;

  const classes = ['btn btn-outline-dark'];
  if (className) {
    classes.push(className);
  }
  if (dropdownItem) {
    classes.push('dropdown-item');
  }
  if (limit && limit < count) {
    classes.push('btn-disabled');
  }
  return (
    <>
      { limit && limit < count ? (
        <OverlayTrigger
          placement="top"
          overlay={(
            <Tooltip>
              <Trans>This action cannot be performed with more than { limit } elements.</Trans>
            </Tooltip>
          )}>
          <button type="button" className={classes.join(' ')}>
            <Trans>Export</Trans>
            <span className="badge text-bg-secondary ms-2">{ size }</span>
          </button>
        </OverlayTrigger>
      ) : (
        <button type="button" className={classes.join(' ')} onClick={() => { setShow(true); }}>
          <Trans>Export</Trans>
          {!dropdownItem && (
            <span className="badge text-bg-secondary ms-2">{ size }</span>
          )}
        </button>
      ) }

      <Modal
        enforceFocus={false}
        show={show}
        onHide={() => { setShow(false); }}
        className="bulk-items-modal"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="backlog-title">
            <Trans>Export {size} items</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BulkExportModalContent requestParams={requestParams} setShow={setShow} count={count} pks={pks} />
        </Modal.Body>
      </Modal>

    </>
  );
}
BulkExportModal.defaultProps = {
  count: null,
  pks: null,
  dropdownItem: false,
  requestParams: new URLSearchParams()
};

export default BulkExportModal;
