import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Trans, t } from '@lingui/macro';
import { Dropdown } from 'react-bootstrap';
import Icon from 'components/ui/Icon';
import DateFormat from 'components/ui/DateFormat';
import SimpleModal from 'components/modal/SimpleModal';
import { NotificationsContext } from 'components/utils/NotificationsObserver';

const statusList = {
  queued: {
    icon: 'hourglass',
    color: 'yellow'
  },
  started: {
    icon: 'hourglass',
    color: 'yellow'
  },
  done: {
    icon: 'check',
    color: 'lime'
  },
  warning: {
    icon: 'exclamation-circle',
    color: 'orange'
  },
  failed: {
    icon: 'x-circle',
    color: 'red'
  },
  not_queued: {
    icon: 'x-circle',
    color: 'red'
  }
};

function Job(props) {
  const { job, setJobs } = props;
  const status = statusList[job.status.value];

  const deleteJob = () => {
    axios.delete('/user-jobs/' + job.pk).then(() => {
      setJobs(cur => {
        const newItems = [...cur.items];
        newItems.splice(
          newItems.findIndex(c => c.pk === job.pk),
          1
        );
        return {
          meta: cur.meta,
          items: newItems
        };
      });
    }).catch((err) => {
      console.error('Impossible to update jobs');
    });
  };
  return (
    <div className="card mb-2">
      <div className="card-body py-2">
        <div className="hstack">
          <small>
            <DateFormat
              month="short"
              year="numeric"
              day="numeric"
              hour="numeric"
              minute="numeric"
              datestring={job.created_at}/>
          </small>
          { ['done', 'warning', 'failed'].includes(job.status.value) && (
            <button type="button" className="ms-auto btn btn-simplelink mb-2" onClick={deleteJob}>
              <Icon name="x-circle" title={t`Delete`}/>
            </button>
          ) }
        </div>
        <div className="hstack">
          <strong>{ job.resource_name }</strong>{' '}
          <span className={'ms-auto p-1 align-self-start d-inline-block bg-col-' + status.color}>
            { job.status.label }{' '}
            <Icon size="16" name={status.icon}/>
          </span>
        </div>
        { 'summary' in job && job.summary && (
          <p>
            { job.summary }
          </p>
        ) }
        { job.file && (
          <a
            className="btn btn-outline-secondary"
            href={`${process.env.REACT_APP_FILE_URL}/${job.file.key}`}
            rel="noreferrer"
            target="_blank">
            <Icon name="file-earmark"/>{' '}
            <Trans>Download file</Trans>
          </a>
        ) }
        { job.message && (
          <SimpleModal size="lg" title={job.resource_name} buttonTitle={t`View job detail`} variant="simplelink">
            <strong>
              <DateFormat
                month="short"
                year="numeric"
                day="numeric"
                hour="numeric"
                minute="numeric"
                datestring={job.created_at}/>
            </strong>
            {/* eslint-disable-next-line react/no-danger */ }
            <div className="mt-3 job-html" dangerouslySetInnerHTML={{ __html: job.message }} />
          </SimpleModal>
        ) }
      </div>
    </div>
  );
}

function Jobs() {
  const [jobs, setJobs] = useState(null);
  const { isActive } = useContext(NotificationsContext);
  const baseSize = 3;

  const loadJobs = useCallback((size, offset, append) => {
    const date = (new Date(Date.now() - 86400000)).toISOString().split('T')[0];
    axios.get(`/user-jobs?status_exclude=not_queued&created_after=${date}` +
        `&subset=offset&limit=${size}&offset=${offset}&order=-created_at`).then(res => {
      setJobs(current => {
        if (current === null || !append) return res.data;
        return {
          meta: res.data.meta,
          items: [...current.items, ...res.data.items]
        };
      });
    }).catch((err) => {
      console.error('Impossible to load jobs');
    });
  }, []);

  const onDropdownToggle = (open) => {
    if (open) {
      for (const j of jobs.items) {
        if (j.status.value !== (j.read_status && j.read_status.value)) {
          axios.put(`/user-jobs/${j.pk}`, { read_status: j.status }).then(res => {
            setJobs(current => ({
              meta: current.meta,
              items: current.items.map(
                cj => (cj.pk === j.pk ? { ...cj, read_status: j.status } : cj)) }));
          }).catch((err) => {
            console.error('Impossible to update jobs');
          });
        }
      }
    }
  };

  const onLoadMore = () => {
    loadJobs(baseSize, jobs.items.length, true);
  };

  const jobListener = useCallback((e) => {
    if (e.detail.message.model === 'UserJob') {
      if (e.detail.message.event === 'status_changed' && e.detail.message.item.status.value !== 'not_queued') {
        setJobs(current => {
          if (current === null) {
            return {
              meta: { subset: { count: 1, offset: 0 } },
              items: [e.detail.message.item]
            };
          }
          const existingIndex = current.items.findIndex(i => i.pk === e.detail.message.item.pk);
          if (existingIndex !== -1) {
            current.items[existingIndex] = e.detail.message.item;
            return { ...current };
          }
          return {
            meta: { subset: { ...current.meta.subset, count: current.meta.subset.count + 1 } },
            items: [e.detail.message.item, ...current.items]
          };
        });
      }
      else if (e.detail.message.event === 'deleted') {
        setJobs(current => {
          if (current === null) {
            return current;
          }
          const existingIndex = current.items.findIndex(i => i.pk === e.detail.message.item.pk);
          if (existingIndex !== -1) {
            current.items.splice(existingIndex, 1);
            return {
              meta: { subset: { ...current.meta.subset, count: current.meta.subset.count - 1 } },
              items: current.items
            };
          }
          return current;
        });
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener('notification', jobListener);
    return () => {
      document.removeEventListener('notification', jobListener);
    };
  }, [jobListener]);

  useEffect(() => {
    if (jobs === null || (jobs.items.length < baseSize && jobs.meta.subset.count > jobs.items.length)) {
      loadJobs(baseSize, 0);
    }
  }, [loadJobs, jobs, baseSize]);

  if (isActive === false || jobs === null || jobs.items.length < 1) return null;

  const unreadCount = jobs.items.filter(
    j => j.status.value !== (j.read_status && j.read_status.value)).length;

  return (
    <Dropdown className="jobs-dropdown notifications-dropdown" onToggle={onDropdownToggle}>
      <Dropdown.Toggle id="jobs-dropdown" className="nav-link" variant="link">
        <Icon name="hourglass" title={t`Jobs`} />
        <span className="d-none d-md-inline ms-1">
          <Trans>Jobs</Trans>
        </span>
        { unreadCount && (
          <span className="ms-2 badge bg-theme rounded-pill">{ unreadCount }</span>
        ) }
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-2" align="end">
        {jobs.items.length && jobs.items.map((j) => (
          <Dropdown.Item
            as={Job}
            key={j.pk}
            job={j}
            setJobs={setJobs}>
          </Dropdown.Item>
        ))}
        <div className="text-center">
          { jobs.meta.subset.count > jobs.items.length && (
            <button type="button" className="btn btn-outline-secondary me-2" onClick={onLoadMore}>
              <Trans>Load more</Trans>
            </button>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Jobs;
